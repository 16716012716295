import React, { Component } from "react";
import Header from "../HeaderSection/Header";
import FooterSection from "../FooterSection/Footer";
import Breadcrumb from "../Blogs/Breadcrumb";

class IdeBIdakPrivacyPolicy extends Component {
  state = {
    initData: [],
  };
  render() {
    return (
      <div>
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header imageData={"/img/logo-colored.png"} />
          <Breadcrumb title="Privacy Policy" />
          <div className="main"></div>
          <h3 className="nav-link" style={{ marginTop: "20px" }}>
            ideBidak Mobile Application Privacy Policy
          </h3>
          <h5 className="nav-link" style={{ marginTop: "20px" }}>
            Effective Date: October 3, 2024
          </h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            At ideBidak, your privacy is important to us. This Privacy Policy
            explains how we collect, use, and protect your personal information
            when you use our mobile application and services. Please read this
            policy carefully to understand our practices.
          </p>
          <h5 className="nav-link">1. Information We Collect</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            a. Personal Information: When you create an account, post a request,
            or offer help, we may collect personal information such as your
            name, email address, phone number, and location.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            b. Usage Information: We collect data on how you interact with the
            app, such as the posts you view and the actions you take within the
            app.
          </p>
          <h5 className="nav-link">2. How We Use Your Information</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            a. Facilitating Connections: We use your information to connect
            users requesting and offering help.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            b. Improving Functionality: Your information helps us improve the
            app’s functionality and enhance the user experience.
          </p>
          <p className="nav-link" style={{ margin: "20px" }}>
            c. Communication: We may use your information to communicate
            updates, security alerts, or other relevant information.
          </p>
          <h5 className="nav-link">3. How We Protect Your Information</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            We use industry-standard security measures to protect your personal
            information. However, no method of transmission over the internet is
            completely secure, and we cannot guarantee absolute security.
          </p>
          <h5 className="nav-link">4. Third-Party Services</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            We may use third-party services for analytics and messaging
            purposes. These third-party services are governed by their own
            privacy policies.
          </p>
          <h5 className="nav-link">5. Your Consent</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            By using ideBidak, you consent to the collection and use of your
            information as outlined in this Privacy Policy.
          </p>
          <h5 className="nav-link">6. Changes to This Policy</h5>
          <p className="nav-link" style={{ margin: "20px" }}>
            We may update this Privacy Policy from time to time. Any changes
            will be posted within the app, and your continued use of the app
            after such changes signifies your acceptance of the updated policy.
          </p>
          <h5 className="nav-link">7. Contact Us</h5>
          <h5 className="nav-link">ideBidak</h5>
          <h5 className="nav-link">Beirut, Lebanon</h5>
          <h5 className="nav-link">moussa@furrbud.com</h5>
          <h5 className="nav-link">+96176793559</h5>
          <h5 className="nav-link">Thank you for using ideBidak!</h5>
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default IdeBIdakPrivacyPolicy;
